@font-face {
  font-family: 'ABC Social Variable';
  font-weight: 300 700;
  src: local('ABC Social Variable'), url(./assets/fonts/ABCSocialVariable.woff2) format('woff2');
}

@font-face {
  font-family: 'Rector Web Regular';
  src: local('Rector Web Regular'), url(./assets/fonts/RectorWebRegular.woff2) format('woff2');
}

#authHook {
  width: 1px;
  height: 1px;
  position: absolute;
  display: block;
}
